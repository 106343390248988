















import { ActionMethod, mapActions, mapState } from 'vuex'
import { Component, Vue, Prop } from "vue-property-decorator";
import { Action } from 'vuex-class';
import { IError } from '@/types/interfaces';

@Component
export default class InstallPage extends Vue {
  private shopUrl: string = '';

  @Action('install', { namespace: 'shopify' }) install: ActionMethod;

  async save() {
    this.install(this.shopUrl).then(result => {
      window.location = result.url
    }).catch((err: IError) => {
      alert(err)
    })
  }

}
